<template>
  <div class="aboutContainer">
    <div class="section aboutSection" v-for="(meetClementSingle, index) in meetClement" :key="index">
      <div class="part1">
        <img class="clementAbout" v-if="index != 0" :src="meetClementStore[index].bigImage">
        <div v-else class="revealContent">
          <div class="reveal"></div>
          <!-- SVG STARTS! -->
          <transition name="opacitySwitch"> 
            <svg v-if="section == 2" x="0" y="0" viewBox="0 0 1024 768">
              
              <!-- Defs -->
              <defs>
                <pattern id="disco-gif" width="1.8" height="1.8" viewBox="0 0 300 300" preserveAspectRatio="xMidYMid slice"> 
                  <image class='twombly' xlink:href="https://s3.amazonaws.com/space-jam/disco.gif" width="300" height="300" />
            <!--       <image width="300" height="300" href="https://images.unsplash.com/photo-1503218751919-1ea90572e609?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&ixid=eyJhcHBfaWQiOjE0NTg5fQ" /> -->
                </pattern>
              </defs>
                
              <!-- Filter - Star Glow! -->
              <!-- Make filter and set the filter bounding region -->
              <filter id="glow" x="-40%" y="-40%" width="200%" height="200%">   	    
                <!-- Blur the star / origin image input 'SourceAlpha' -->
                <feGaussianBlur in="SourceAlpha" stdDeviation="4" result="blurred" />
                <!-- Flood / Fill with the desired color -->
                <feFlood flood-color="#fff" result="colored"/>
                <!-- Composite (join) the 2 above together -->
                <feComposite in="colored" in2="blurred" operator="in" result="softGlow_colored" />
                <!-- Merge all together -->
                <feMerge>
                  <feMergeNode in="softGlow_colored" />
                  <feMergeNode in="SourceGraphic" />
                </feMerge>
              </filter>

              <!-- Clouds -->
              <g id="Clouds">
                <path id="Cloud_2" class="BasicFill" stroke="red" d="M506.445,345.087c0.712-1.616,1.103-3.343,1.103-5.14c0-8.953-9.568-16.211-21.37-16.211 c-2.511,0-4.92,0.331-7.158,0.935c-5.561-8.408-17.223-14.199-30.713-14.199c-10.617,0-20.103,3.586-26.373,9.211 c-4.588-2.093-10.121-3.316-16.076-3.316c-15.872,0-28.739,8.683-28.739,19.394c0,3.381,1.285,6.559,3.538,9.326H506.445z"/>
                <path id="Cloud_1" class="BasicFill" d="M231.037,260.659H318.6c0.007-0.006,0.014-0.011,0.021-0.017 c0.003,0.006,0.007,0.011,0.01,0.017h44.46c1.415-2.652,2.209-5.599,2.209-8.707c0-11.576-10.942-20.961-24.44-20.961 c-7.236,0-13.736,2.699-18.211,6.986c-4.64-6.813-14.302-11.5-25.475-11.5c-3.017,0-5.922,0.344-8.65,0.977 c-2.905-7.659-11.746-13.231-22.206-13.231c-12.823,0-23.218,8.374-23.218,18.703c0,1.093,0.123,2.162,0.346,3.204 c-10.251,2.483-17.454,8.342-17.454,15.177C225.993,254.782,227.86,258.004,231.037,260.659z"/>
              </g>

              <!-- Stars -->
              <g id="Stars">
                <polygon id="star_8" points="873.717,205.952 873.43,208.748 875.571,210.57 872.823,211.161 871.752,213.76 
                    870.34,211.329 867.538,211.113 869.414,209.02 868.753,206.287 871.323,207.425"/>
                <polygon id="star_7" points="862.807,90.731 865.556,93.98 869.792,93.557 867.551,97.176 869.263,101.073 
                    865.129,100.061 861.951,102.892 861.636,98.648 857.961,96.501 861.901,94.89"/>
                <polygon id="star_6" points="731.852,110.904 729.874,108.364 726.66,108.552 728.465,105.886 727.292,102.888 
                    730.386,103.781 732.875,101.739 732.982,104.957 735.693,106.693 732.665,107.789"/>
                <polygon id="star_5" points="496.969,140.654 498.013,144.781 502.004,146.26 498.402,148.528 498.229,152.78 
                    494.959,150.056 490.861,151.205 492.441,147.253 490.082,143.711 494.329,143.993"/>
                <polygon id="srar_4" points="217.801,319.11 217.534,321.71 219.524,323.403 216.969,323.952 215.974,326.368 
                    214.662,324.108 212.057,323.908 213.8,321.962 213.186,319.422 215.575,320.479"/>
                <polygon id="star_3" points="295.451,107.324 295.189,109.874 297.141,111.535 294.635,112.073 293.659,114.443 
                    292.372,112.227 289.817,112.03 291.527,110.121 290.925,107.631 293.268,108.667"/>
                <polygon id="star_2" points="126.527,327.528 126.24,330.325 128.38,332.146 125.632,332.737 124.561,335.336 123.15,332.905 120.347,332.69 122.223,330.596 121.562,327.864 124.133,329.001"/>
                <polygon id="star_1" points="176.634,237.079 176.199,241.313 179.441,244.072 175.28,244.967 173.658,248.902 171.521,245.221 167.277,244.894 170.118,241.725 169.117,237.588 173.009,239.31"/>
              </g>
              
              <!-- Duplicate the stars for the glow -->
              <g id="Stars-glow">
                <use id="star_8_dup" href="#star_8" />
                <use id="star_7_dup" href="#star_7" />
                <use id="star_6_dup" href="#star_6" />
                <use id="star_5_dup" href="#star_5" />
                <use id="star_4_dup" href="#star_4" />
                <use id="star_3_dup" href="#star_3" />
                <use id="star_2_dup" href="#star_2" />
                <use id="star_1_dup" href="#star_1" />
              </g>

              <!-- Moon -->
              <g id="Moon">
                <circle class="BasicFill" cx="191.377" cy="133.172" r="32.083"/>
                <circle class="BasicFill" cx="211.218" cy="149.636" r="2.111"/>
                <circle class="BasicFill" cx="170.27" cy="117.975" r="3.377"/>
                <ellipse class="BasicFill" cx="210.796" cy="141.615" rx="4.221" ry="5"/>
              </g>

              <!-- Background Buildings -->
              <g id="BackgroundBuildings">
                <rect class="bg-building BasicFill" id="BuildingB_6" x="491.098" y="505.501" width="69.653" height="102.58"/>
                <rect class="bg-building BasicFill" id="BuildingB_5" x="709.345" y="492.837" width="40.526" height="115.245"/>
                <rect class="bg-building BasicFill" id="BuildingB_4" x="364.878" y="479.751" width="57.411" height="128.331"/>
                <g class="bg-building" id="BuildingB_3">
                  <line class="BasicStroke" x1="475.479" y1="412.63" x2="475.479" y2="455.689"/>
                  <rect x="443.396" y="455.266" class="BasicFill" width="64.166" height="152.815"/>
                  <rect x="466.192" y="428.671" class="BasicFill" width="19.419" height="12.664"/>
                </g>
                <g class="bg-building" id="BuildingB_2">
                  <rect x="309.999" y="452.734" class="BasicFill" width="57.411" height="155.348"/>
                  <line class="BasicStroke" x1="309.999" y1="466.242" x2="366.566" y2="466.242"/>
                </g>
                <g class="bg-building" id="BuildingB_1">
                  <rect x="544.393" y="487.654" class="BasicFill" width="48.019" height="120.428"/>
                  <rect x="547.194" y="472.215" class="BasicFill" width="42.416" height="15.439"/>
                  <rect x="550.396" y="456.775" class="BasicFill" width="36.014" height="15.439"/>
                  <rect x="555.998" y="441.336" class="BasicFill" width="24.81" height="15.439"/>
                </g>
              </g>

              <!-- Old City Hall -->
              <g id="OldCityHall">
                <rect id="tower_body" x="778.401" y="459.488" class="BasicFill" width="41.421" height="148.594"/>
                <path id="tower_roof" class="BasicFill" d="M824.237,458.04l-24.232-48.33c-0.369-0.736-1.419-0.736-1.788,0l-24.232,48.33 c-0.333,0.665,0.15,1.448,0.894,1.448h48.464C824.087,459.488,824.571,458.705,824.237,458.04z"/>
                <path id="tower_door" class="BasicFill" d="M808.394,564.266c0-4.711-4.124-8.53-9.211-8.53s-9.211,3.819-9.211,8.53 c0,0.131,0.015,0.258,0.021,0.388h-0.021v43.427h18.421v-43.427h-0.021C808.379,564.525,808.394,564.397,808.394,564.266z"/>
                <circle id="tower_clock" class="BasicFill" cx="798.839" cy="479.328" r="9.709"/>
                <rect id="lower_building" x="673.885" y="555.314" class="BasicFill" width="104.691" height="52.768"/>
                <path id="window_3" class="BasicFill" d="M763.647,582.641c0-2.736-4.124-4.953-9.211-4.953s-9.211,2.218-9.211,4.953 c0,0.076,0.015,0.15,0.021,0.225h-0.021v25.216h18.421v-25.216h-0.021C763.632,582.791,763.647,582.717,763.647,582.641z"/>
                <path id="window_2" class="BasicFill" d="M735.786,582.641c0-2.736-4.124-4.953-9.211-4.953c-5.087,0-9.211,2.218-9.211,4.953 c0,0.076,0.015,0.15,0.021,0.225h-0.021v25.216h18.421v-25.216h-0.021C735.771,582.791,735.786,582.717,735.786,582.641z"/>
                <path id="window_1" class="BasicFill" d="M707.924,582.641c0-2.736-4.124-4.953-9.211-4.953s-9.211,2.218-9.211,4.953 c0,0.076,0.015,0.15,0.021,0.225h-0.021v25.216h18.421v-25.216h-0.021C707.909,582.791,707.924,582.717,707.924,582.641z"/>
                <path id="lower_roof" class="BasicFill" d="M778.138,527.415l-105.39,0.074c-0.366,0-0.688,0.246-0.792,0.604l-8.58,29.556 c-0.157,0.541,0.24,1.085,0.793,1.084l122.591-0.086c0.553,0,0.949-0.544,0.792-1.085l-8.621-29.544 C778.826,527.66,778.504,527.415,778.138,527.415z"/>
              </g>

              <!-- CN Tower -->
              <g id="CNTower">
                <path class="cn-tower" id="cn_base_l" d="M360.76,289.317c0,0-3.41,241.954-15.831,318.765c0,0,12.565,0,26.182,0V289.317H360.76z"/>
                <path class="cn-tower" id="cn_base_r" d="M381.097,289.317h-9.986v318.765c9.526,0,19.566,0,26.182,0 C385.115,531.271,381.097,289.317,381.097,289.317z"/>
                <path class="cn-tower" id="lower_deck" d="M396.017,261.503h-49.813v-7.287c0-1.105,0.895-2,2-2h45.813c1.105,0,2,0.895,2,2 V261.503z"/>
                <path class="cn-tower" id="main_deck" d="M398.661,282.61h-55.1c-1.105,0-2-0.895-2-2v-17.107c0-1.105,0.895-2,2-2h55.1 c1.105,0,2,0.895,2,2v17.107C400.661,281.715,399.765,282.61,398.661,282.61z"/>
                <rect class="cn-tower" id="inner_deck" x="341.561" y="267" width="59.1" height="10.012"/>
                <path class="cn-tower" id="upper_deck" d="M393.595,289.365h-44.968c-1.105,0-2-0.895-2-2v-4.754h48.968v4.754 C395.595,288.469,394.7,289.365,393.595,289.365z"/>
                <rect class="cn-tower" id="needle_base_1" x="364.779" y="242.929" width="12.664" height="9.287"/>
                <rect class="cn-tower" id="needle_base_2" x="366.89" y="208.314" width="8.443" height="34.616"/>
                <line id="needle" x1="371.111" y1="96.868" x2="371.111" y2="207.469"/>
              </g>

              <!-- CityHall -->
              <g id="CityHallL">
                <polygon id="city_hall_body_left" class="CityHallBody BasicFill" points="589.879,608.082 651.321,608.082 651.321,464.11 589.879,421.451 		"/>
                <line class="BasicStroke" x1="639.801" y1="477.657" x2="639.801" y2="608.082"/>
                <line class="BasicStroke" x1="630.008" y1="470.451" x2="630.008" y2="608.082"/>
                <line class="BasicStroke" x1="620.216" y1="463.245" x2="620.216" y2="608.082"/>
                <line class="BasicStroke" x1="610.424" y1="455.319" x2="610.424" y2="608.082"/>
                <line class="BasicStroke" x1="600.631" y1="446.672" x2="600.631" y2="608.082"/>
              </g>
              <g id="CityHallR">
                <polygon id="city_hall_body_right" class="CityHallBody BasicFill" points="708.923,608.082 647.481,608.082 647.481,487.296 708.923,451.508"/>
                <line class="BasicStroke" x1="659.001" y1="498.661" x2="659.001" y2="608.082"/>
                <line class="BasicStroke" x1="668.794" y1="492.616" x2="668.794" y2="608.082"/>
                <line class="BasicStroke" x1="678.586" y1="486.571" x2="678.586" y2="608.082"/>
                <line class="BasicStroke" x1="688.378" y1="479.921" x2="688.378" y2="608.082"/>
                <line class="BasicStroke" x1="698.171" y1="472.667" x2="698.171" y2="608.082"/>
              </g>

              <!-- Another Building -->
              <g id="Building" class="bg-building">
                <rect x="400.76" y="496.636" class="BasicFill" width="48.124" height="111.445"/>
                <rect x="400.76" y="496.636" class="BasicFill" width="48.124" height="12.664"/>
                <line class="BasicStroke" x1="408.358" y1="520.276" x2="441.285" y2="520.276"/>
                <line class="BasicStroke" x1="408.358" y1="528.438" x2="441.285" y2="528.438"/>
                <line class="BasicStroke" x1="408.358" y1="536.599" x2="441.285" y2="536.599"/>
                <line class="BasicStroke" x1="408.358" y1="544.76" x2="441.285" y2="544.76"/>
                <line class="BasicStroke" x1="408.358" y1="552.922" x2="441.285" y2="552.922"/>
                <line class="BasicStroke" x1="408.358" y1="561.083" x2="441.285" y2="561.083"/>
                <line class="BasicStroke" x1="408.358" y1="569.245" x2="441.285" y2="569.245"/>
                <line class="BasicStroke" x1="408.358" y1="577.406" x2="441.285" y2="577.406"/>
                <line class="BasicStroke" x1="408.358" y1="585.567" x2="441.285" y2="585.567"/>
                <line class="BasicStroke" x1="408.358" y1="593.729" x2="441.285" y2="593.729"/>
              </g>

              <!-- ROM -->
              <g id="ROM">
                <polygon class="BasicFill" points="465.386,530.177 439.597,574.234 466.132,608.082 515.143,608.082 531.47,565.637"/>
                <polygon class="BasicFill" points="560.483,519.432 525.56,554.355 528.246,608.082 587.137,608.082 623.881,565.637"/>
                <polygon class="BasicFill" points="552.424,560.265 496.01,536.625 504.069,608.082 534.156,608.082"/>
                <polygon class="BasicFill" points="457.046,544.424 492.249,577.994 487.951,595.724 452.633,551.963"/>
                <polygon class="BasicFill" points="554.579,608.082 570.691,569.398 581.436,572.622 566.222,608.082"/>
                <polygon class="BasicFill" points="516.964,587.128 516.964,608.082 525.56,608.082 531.47,577.994"/>
                <polygon class="BasicFill" points="541.173,538.742 564.244,554.892 551.81,561.871 529.017,550.456"/>
              </g>

              <!-- Lake Waves -->
              <g id="Waves">
                <path id="wave_4" class="BasicStroke" d="M648.979,664c5.217,0,9.695-1.813,11.628-4.402c1.933,2.588,6.411,4.402,11.628,4.402 c5.217,0,9.695-1.813,11.628-4.402c1.933,2.588,6.411,4.402,11.628,4.402c5.217,0,9.695-1.813,11.628-4.402 c1.933,2.588,6.411,4.402,11.628,4.402c5.217,0,9.695-1.813,11.628-4.402c1.933,2.588,6.411,4.402,11.628,4.402 c5.217,0,9.695-1.813,11.628-4.402c1.933,2.588,6.411,4.402,11.628,4.402c5.217,0,9.695-1.813,11.628-4.402 c1.933,2.588,6.411,4.402,11.628,4.402c5.217,0,9.695-1.813,11.628-4.402c1.933,2.588,6.411,4.402,11.628,4.402 c5.217,0,9.695-1.813,11.628-4.402c1.933,2.588,6.411,4.402,11.628,4.402c5.217,0,9.695-1.813,11.628-4.402 c1.933,2.588,6.411,4.402,11.628,4.402c5.217,0,9.695-1.813,11.628-4.402c1.933,2.588,6.411,4.402,11.628,4.402 c5.217,0,9.695-1.813,11.628-4.402c1.933,2.588,6.411,4.402,11.628,4.402"/>
                <path id="wave_3" class="BasicStroke" d="M502.074,639.938c5.217,0,9.695-1.813,11.628-4.402c1.933,2.588,6.411,4.402,11.628,4.402 c5.217,0,9.695-1.813,11.628-4.402c1.933,2.588,6.411,4.402,11.628,4.402c5.217,0,9.695-1.813,11.628-4.402
            c1.933,2.588,6.411,4.402,11.628,4.402c5.217,0,9.695-1.813,11.628-4.402c1.933,2.588,6.411,4.402,11.628,4.402 c5.217,0,9.695-1.813,11.628-4.402c1.933,2.588,6.411,4.402,11.628,4.402c5.217,0,9.695-1.813,11.628-4.402
            c1.933,2.588,6.411,4.402,11.628,4.402c5.217,0,9.695-1.813,11.628-4.402c1.933,2.588,6.411,4.402,11.628,4.402 c5.217,0,9.695-1.813,11.628-4.402c1.933,2.588,6.411,4.402,11.628,4.402c5.217,0,9.695-1.813,11.628-4.402
            c1.933,2.588,6.411,4.402,11.628,4.402c5.217,0,9.695-1.813,11.628-4.402c1.933,2.588,6.411,4.402,11.628,4.402 c5.217,0,9.695-1.813,11.628-4.402c1.933,2.588,6.411,4.402,11.628,4.402"/>
                <path id="wave_2" class="BasicStroke" d="M298.601,664c5.217,0,9.695-1.813,11.628-4.402c1.933,2.588,6.411,4.402,11.628,4.402 c5.217,0,9.695-1.813,11.628-4.402c1.933,2.588,6.411,4.402,11.628,4.402c5.217,0,9.695-1.813,11.628-4.402 c1.933,2.588,6.411,4.402,11.628,4.402s9.695-1.813,11.628-4.402c1.933,2.588,6.411,4.402,11.628,4.402 c5.217,0,9.695-1.813,11.628-4.402c1.933,2.588,6.411,4.402,11.628,4.402c5.217,0,9.695-1.813,11.628-4.402
            c1.933,2.588,6.411,4.402,11.628,4.402c5.217,0,9.695-1.813,11.628-4.402c1.933,2.588,6.411,4.402,11.628,4.402 s9.695-1.813,11.628-4.402c1.933,2.588,6.411,4.402,11.628,4.402c5.217,0,9.695-1.813,11.628-4.402
            c1.933,2.588,6.411,4.402,11.628,4.402c5.217,0,9.695-1.813,11.628-4.402c1.933,2.588,6.411,4.402,11.628,4.402 s9.695-1.813,11.628-4.402c1.933,2.588,6.411,4.402,11.628,4.402"/>
                <path id="wave_1" class="BasicStroke" d="M139.032,639.938c5.217,0,9.695-1.813,11.628-4.402c1.933,2.588,6.411,4.402,11.628,4.402 c5.217,0,9.695-1.813,11.628-4.402c1.933,2.588,6.411,4.402,11.628,4.402c5.217,0,9.695-1.813,11.628-4.402
            c1.933,2.588,6.411,4.402,11.628,4.402c5.217,0,9.695-1.813,11.628-4.402c1.933,2.588,6.411,4.402,11.628,4.402 c5.217,0,9.695-1.813,11.628-4.402c1.933,2.588,6.411,4.402,11.628,4.402c5.217,0,9.695-1.813,11.628-4.402
            c1.933,2.588,6.411,4.402,11.628,4.402c5.217,0,9.695-1.813,11.628-4.402c1.933,2.588,6.411,4.402,11.628,4.402 c5.217,0,9.695-1.813,11.628-4.402c1.933,2.588,6.411,4.402,11.628,4.402s9.695-1.813,11.628-4.402
            c1.933,2.588,6.411,4.402,11.628,4.402c5.217,0,9.695-1.813,11.628-4.402c1.933,2.588,6.411,4.402,11.628,4.402 c5.217,0,9.695-1.813,11.628-4.402c1.933,2.588,6.411,4.402,11.628,4.402"/>
              </g>
              <!-- Waves Inverse for morphing-->
              <g id="Waves-I">
                <path id="wave_i_4" class="BasicStroke" d="M649,659.6c5.22,0,9.69,1.81,11.63,4.4,1.93-2.59,6.41-4.4,11.63-4.4s9.69,1.81,11.62,4.4c1.94-2.59,6.41-4.4,11.63-4.4s9.7,1.81,11.63,4.4c1.93-2.59,6.41-4.4,11.63-4.4s9.69,1.81,11.63,4.4c1.93-2.59,6.41-4.4,11.62-4.4s9.7,1.81,11.63,4.4c1.93-2.59,6.41-4.4,11.63-4.4s9.69,1.81,11.63,4.4c1.93-2.59,6.41-4.4,11.63-4.4s9.69,1.81,11.62,4.4c1.94-2.59,6.42-4.4,11.63-4.4s9.7,1.81,11.63,4.4c1.93-2.59,6.41-4.4,11.63-4.4s9.69,1.81,11.63,4.4c1.93-2.59,6.41-4.4,11.62-4.4s9.7,1.81,11.63,4.4c1.94-2.59,6.41-4.4,11.63-4.4s9.7,1.81,11.63,4.4c1.93-2.59,6.41-4.4,11.63-4.4"/>
                <path id="wave_i_3" class="BasicStroke" d="M502.07,635.54c5.22,0,9.7,1.81,11.63,4.4,1.93-2.59,6.41-4.4,11.63-4.4s9.69,1.81,11.63,4.4c1.93-2.59,6.41-4.4,11.63-4.4s9.69,1.81,11.62,4.4c1.94-2.59,6.42-4.4,11.63-4.4s9.7,1.81,11.63,4.4c1.93-2.59,6.41-4.4,11.63-4.4s9.69,1.81,11.63,4.4c1.93-2.59,6.41-4.4,11.62-4.4s9.7,1.81,11.63,4.4c1.94-2.59,6.41-4.4,11.63-4.4s9.7,1.81,11.63,4.4c1.93-2.59,6.41-4.4,11.63-4.4s9.69,1.81,11.62,4.4c1.94-2.59,6.42-4.4,11.63-4.4s9.7,1.81,11.63,4.4c1.93-2.59,6.41-4.4,11.63-4.4s9.69,1.81,11.63,4.4c1.93-2.59,6.41-4.4,11.63-4.4s9.69,1.81,11.62,4.4c1.94-2.59,6.41-4.4,11.63-4.4"/>
                <path id="wave_i_2" class="BasicStroke" d="M298.6,659.6c5.22,0,9.7,1.81,11.63,4.4,1.93-2.59,6.41-4.4,11.63-4.4s9.69,1.81,11.63,4.4c1.93-2.59,6.41-4.4,11.62-4.4s9.7,1.81,11.63,4.4c1.93-2.59,6.41-4.4,11.63-4.4s9.69,1.81,11.63,4.4c1.93-2.59,6.41-4.4,11.63-4.4s9.69,1.81,11.62,4.4c1.94-2.59,6.42-4.4,11.63-4.4s9.7,1.81,11.63,4.4c1.93-2.59,6.41-4.4,11.63-4.4s9.69,1.81,11.63,4.4c1.93-2.59,6.41-4.4,11.62-4.4s9.7,1.81,11.63,4.4c1.94-2.59,6.41-4.4,11.63-4.4s9.7,1.81,11.63,4.4c1.93-2.59,6.41-4.4,11.63-4.4s9.69,1.81,11.62,4.4c1.94-2.59,6.42-4.4,11.63-4.4s9.7,1.81,11.63,4.4c1.93-2.59,6.41-4.4,11.63-4.4"/>
                <path id="wave_i_1" class="BasicStroke" d="M139,635.54c5.22,0,9.7,1.81,11.63,4.4,1.93-2.59,6.41-4.4,11.63-4.4s9.69,1.81,11.63,4.4c1.93-2.59,6.41-4.4,11.62-4.4s9.7,1.81,11.63,4.4c1.94-2.59,6.41-4.4,11.63-4.4s9.7,1.81,11.63,4.4c1.93-2.59,6.41-4.4,11.63-4.4s9.69,1.81,11.62,4.4c1.94-2.59,6.42-4.4,11.63-4.4s9.7,1.81,11.63,4.4c1.93-2.59,6.41-4.4,11.63-4.4s9.69,1.81,11.63,4.4c1.93-2.59,6.41-4.4,11.62-4.4s9.7,1.81,11.63,4.4c1.94-2.59,6.41-4.4,11.63-4.4s9.7,1.81,11.63,4.4c1.93-2.59,6.41-4.4,11.63-4.4s9.69,1.81,11.63,4.4c1.93-2.59,6.41-4.4,11.62-4.4s9.7,1.81,11.63,4.4c1.93-2.59,6.41-4.4,11.63-4.4"/>  
              </g>

              <!-- Rogers Center -->
              <g id="RogersCentre">
                <rect id="building" x="163.447" y="584.442" class="BasicFill" width="224.579" height="23.64"/>
                <rect id="building_inner" x="201.018" y="596.262" class="BasicFill" width="149.438" height="11.82"/>
                <path id="dome_big" class="BasicFill" d="M396.173,584.442c1.208,0,2.037-1.237,1.558-2.346 c-17.883-41.467-55.935-76.188-119.745-77.016c-71.333-0.926-117.273,36.203-124.355,77.338c-0.182,1.055,0.612,2.024,1.683,2.024 H396.173z"/>
                <path id="dome_small" class="BasicFill" d="M364.955,584.442c0.895,0,1.509-0.868,1.154-1.647 c-13.248-29.109-41.436-53.482-88.706-54.064c-52.842-0.65-86.875,25.414-92.121,54.29c-0.135,0.741,0.453,1.421,1.247,1.421 H364.955z"/>
              </g>

              <!-- Streetcar -->
              <g id="Streetcar">
                <path class="streetcar_white" d="M760.844,606.082h-82.329c-3.13,0-5.21-2.945-3.908-5.533l15.06-29.961c1.099-2.186,3.499-3.588,6.142-3.588 h65.035V606.082z"/>
                <rect x="760.844" y="567" class="streetcar_window" width="6.753" height="39.082"/>
                <rect x="830.831" y="567" class="streetcar_window" width="6.753" height="39.082"/>
                <polygon class="streetcar_window" points="695.462,580 760.537,580 760.537,592.845 688.709,592.845"/>
                <rect x="767.597" y="567" class="streetcar_white" width="63.234" height="39.082"/>
                <rect x="767.877" y="580" class="streetcar_window" width="62.674" height="12.845"/>
                <rect x="767.597" y="567" class="streetcar_red" width="63.234" height="7"/>
                <path class="streetcar_white" d="M837.584,606.082h82.329c3.13,0,5.21-2.945,3.908-5.533l-15.06-29.961c-1.099-2.186-3.499-3.588-6.142-3.588 h-65.035V606.082z"/>
                <polygon class="streetcar_window" points="902.967,580 837.891,580 837.891,592.845 909.72,592.845"/>
                <path class="streetcar_red" d="M688.402,574h72.443v-7h-63.316C690.223,567,689.63,571.455,688.402,574L688.402,574z"/>
                <path class="streetcar_red" d="M676.11,597.707h84.734v8.375h-82.301c-3.122,0-5.187-2.97-3.867-5.561L676.11,597.707z"/>
                <rect x="767.597" y="597.707" class="streetcar_red" width="63.234" height="8.375"/>
                <path class="streetcar_red" d="M910.027,574h-72.443v-7H900.9C908.205,567,908.799,571.455,910.027,574L910.027,574z"/>
                <path class="streetcar_red" d="M922.318,597.707h-84.734v8.375h82.301c3.122,0,5.187-2.97,3.867-5.561L922.318,597.707z"/>
              </g>

              <g id="Text">
                <!-- Text - Toronto -->
                <g id="Toronto">
                  <path id="Toronto_1" class="BasicStroke" d="M537.999,296.089c0,0-3.005,7.339,14.777,5.323c18.585-2.107,193.846-50.147,190.289-31.092"/>
                  <path id="Toronto_2" class="BasicStroke" d="M640.259,281.632c0,0-26.736,58.43-19.106,60.124c6.099,1.354,40.771-33.128,39.075-24.789 c-0.387,1.9-12.663,17.492-6.069,22.77c5.041,4.034,16.052-11.731,14.623-20.666c-0.693-4.334-11.766-6.13-11.316-1.122 c0.367,4.09,24.42-3.746,28.941-4.316c4.629-0.584-4.687,20.731-5.229,26.653c-0.542,5.922,5.435-15.776,10.048-21.883 c4.922-6.516,10.217-8.37,10.713-4.343c0.242,1.966,0.323,5.052,2.212,4.184c1.806-0.83,12.113-2.826,11.308-2.465 c-2.317,1.039-11.825,18.822-7.211,23.264c4.179,4.022,15.338-8.29,15.666-18.19c0.328-9.901-7.744-9.668-14.588-2.19 c-3.579,3.911,19.444-3.713,23.47-5.607c6.132-2.884,10.86-1.147,11.447,1.893c0.348,1.805-5.815,17.098-8.9,21.955 c-0.592,0.932,0.312,1.907,0.819,0.913c7.14-14.001,21.339-26.733,21.565-22.422c0.315,6.001-4.005,23.4,1.232,22.885 s29.969-28.763,39.041-41.127c8.896-12.124,12.505-21.69,13.815-26.546c1.31-4.856-4.945-6.653-11.854,2.668 c-6.909,9.32-23.645,48.703-23.195,62.678s21.745-9.066,21.745-9.066s14.223-13.627,10.702-12.954 c-5.469,1.045-11.813,24.097-5.105,27.401c4.966,2.445,15.397-7.547,17.339-19.313c0.645-3.908-2.437-11.171-6.907-9.59 c-4.47,1.581-3.026,4.699-3.026,4.699s3.16,3.275,17.247,0.584s45.286-12.772,50.929-8.274"/>
                  <path id="Toronto_3" class="BasicStroke" d="M747.76,295.697c0,0,10.787,7.599,32.36,5.066c21.573-2.533,65.618-13.509,64.719-9.287"/>
                </g>

                <!-- Text - Hello -->
                <path id="Hello" class="BasicStroke" d="M516.426,212.404c0,0,14.83,20.772,34.224,12.017c19.393-8.755,61.267-42.654,68.067-55.964 c7.986-15.631,2.438-24.149-17.492-5.493c-20.724,19.399-33.912,85.835-33.912,85.835s3.116-8.819,5.603-13.906 c3.022-6.18,13.099-20.085,15.381-7.726c2.281,12.36,3.949,25.473,15.949,16.857c12-8.616,20.094-20.566,21.167-23.552 c1.48-4.12-2.893-10.3-9.738-4.635c-6.844,5.666-20.111,37.179,2.852,29.871c22.963-7.308,35.312-26.05,38.407-30.557 c5.894-8.583,15.78-33.991-1.331-19.57c-14.523,12.239-21.105,41.372-18.253,48.067c2.852,6.696,17.677,10.273,35.647-8.511 c17.969-18.784,25.385-42.99,16.259-40.415c-9.126,2.575-38.669,44.359-19.584,52.36c10.647,4.463,25.67-9.705,29.472-15.324 c3.801-5.619-0.905-5.104-4.898,3.136c-3.938,8.127,2.852,16.351,11.693,7.339c8.948-9.122,1.907-17.53-3.28-15.064 c-2.691,1.279-18.552,11.347-3.993,7.726c13.975-3.476,56.595-11.565,65.738-12.747c9.143-1.182,27.236-1.931,20.106,5.536"/>
              </g>

              <!-- Dividing LIne -->
              <line id="Baseline" class="BasicStroke" x1="87.953" y1="608.082" x2="952.499" y2="608.082"/>
            </svg>
          </transition>
        </div>
        <div>
          <h1>{{meetClementSingle.headline}}</h1>
          <span>
            {{meetClementSingle.par1}}
          </span>
          <span>
            {{meetClementSingle.par2}}
          </span>
          <div class="goToClementLabs" @click="slideToNext()">
            <h3>{{meetClementSingle.button}}</h3>
            <img src="https://ik.imagekit.io/nnol5se8mol/tr:h-50,w-50/downbtn_LWLlPOQ9nq.png">
          </div>
        </div>
      </div>
      <div class="part2">
        <div>
          <h1>{{meetClementSingle.headline}}</h1>
          <span>
            {{meetClementSingle.par1}}
          </span>
          <span>
            {{meetClementSingle.par2}}
          </span>
          <div class="goToClementLabs" @click="index == 0 ? backToTop() : slideToNext()">
            <h3>{{meetClementSingle.button}}</h3>
            <img src="https://ik.imagekit.io/nnol5se8mol/tr:h-50,w-50/downbtn_LWLlPOQ9nq.png">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {TimelineMax, Elastic} from "gsap";
import MorphSVGPlugin from "gsap/MorphSVGPlugin";
import DrawSVGPlugin from "gsap/DrawSVGPlugin";
import { gsap } from "gsap";
gsap.registerPlugin(MorphSVGPlugin, DrawSVGPlugin);

//without this line, CSSPlugin and AttrPlugin may get dropped by your bundler...
// eslint-disable-next-line no-unused-vars
const plugins = [ MorphSVGPlugin, DrawSVGPlugin ];

export default {
  //VueMeta
  metaInfo: {
    title: 'The Enigma behind the Secret',
    meta: [
      {
           //vmid: 'description',
           name: 'description',
           content:
                   'Our Infamous About Page - Clement Labs is a Canadian Company specializing in Shopify services and apps. We offer unique services to help on-board you onto the Shopify Platform and POS in record time and to generally make your Shopify experience better.'
      }
    ]
  },
  /*metaInfo: () => ({
    meta: [{ name: 'description', content: 'Hello', vmid: 'test' }],
  }),*/

  methods: {
    slideToNext() {
      if(!this.transitionInProgress) {
        this.$store.state.section = this.section < document.querySelectorAll('.aboutSection').length - 1 ? this.section + 1 : 0
        this.transitionProgress()
      }
    },

    backToTop() {
      if(!this.transitionInProgress) {
        this.$store.state.section = 0
        this.transitionProgress()
      }
    },

    transitionProgress() {
      this.transitionInProgress = true
      setTimeout(() => {
        this.transitionInProgress = false
      }, 1000)
    },

    startSVG() {
      // Create a new timeline instance
      // var tl = new TimelineMax({
      //   delay: 1
      // });
      var tl = gsap.timeline({delay:1})

      // Start sequencing...
      // Reveal
      tl
        .to(".reveal", 1, {
          y: "100%",
          opacity: 0
        })
        // Animate the Stars & Moon with .staggerFrom and .from
        .staggerFrom(
          "#Stars polygon",
          0.5, {
            opacity: 0,
            y: -10,
            rotation: "80deg",
            transformOrigin: "50% 50%"
          },
          0.1
        )
        .from(
          "#Moon",
           {
            opacity: 0,
          }
        )
        .staggerFrom(
          ".bg-building",
          1, {
            opacity: 0,
            scale: 0.6,
            transformOrigin: "50% 50%",
            ease: Elastic.easeOut.config(1, 0.3),
            y: -500
          },
          0.1,
          "-=1"
        )
        .from(
          "#Baseline",
          2, {
            drawSVG: "50% 50%", // middle of the stroke, out
            x: 50,
            ease: Elastic.easeOut.config(1, 0.3)
          },
          "-=1"
        )
        .from(
          "#OldCityHall .BasicFill",
          0.5, {
            drawSVG: "0",
            fill: "transparent"
          },
          "-=1"
        )
        .from(
          ".CityHallBody",
          0.3, {
            drawSVG: "0",
            fill: "transparent"
          },
          "-=1"
        )
        .from(
          "#CityHallL .BasicStroke",
          0.3, {
            drawSVG: "0"
          },
          "-=0.3"
        )
        .from(
          "#CityHallR .BasicStroke",
          0.3, {
            drawSVG: "0"
          },
          "-=0.3"
        )
        .staggerFrom(
          "#ROM polygon",
          0.5, {
            drawSVG: "0",
            fill: "transparent"
          },
          0.1, // stagger value
          "-=0.1"
        )
        .from('#RogersCentre .BasicFill', .5, {drawSVG: '0', fill: 'transparent'}, '-=.5')
        .from(
          "#needle",
          0.3, {
            drawSVG: "0"
          },
          "-=0.3"
        )
      .from(".cn-tower", 1, {
          opacity: 0
        }, "-=1")
        // .staggerFrom(
        //   ".cn-tower",
        //   1, {
        //     drawSVG: "0",
        //     fill: "transparent",
        //     stroke: "#a9fcfa"
        //   },
        //   0.1,
        //   "-=0.3"
        // )
        .from("#Clouds", 1, {
          opacity: 0
        }, "-=1")
        .to("#Cloud_1", 4, {
          x: 40,
          repeat: -1,
          yoyo: true
        }, "-=4")
        .to("#Cloud_2", 4, {
          x: -40,
          repeat: -1,
          yoyo: true
        }, "-=4")
      // addLabel('Waves')
      .from("#Waves", 0.3, {
          opacity: 0,
          y: -10
        }, "-=1")
        .to(
          "#wave_1",
          1, {
            morphSVG: "#wave_i_1",
            x: 20,
            repeat: -1,
            yoyo: true
          },
          "-=1"
        )
        .to(
          "#wave_2",
          1, {
            morphSVG: "#wave_i_2",
            x: -20,
            repeat: -1,
            yoyo: true
          },
          "-=1"
        )
        .to(
          "#wave_3",
          1, {
            morphSVG: "#wave_i_3",
            x: 20,
            repeat: -1,
            yoyo: true
          },
          "-=1"
        )
        .to(
          "#wave_4",
          1, {
            morphSVG: "#wave_i_4",
            x: -20,
            repeat: -1,
            yoyo: true
          },
          "-=1"
        )
        .from('#Hello', 2, { drawSVG: '0'}, "-=0.3")
        .from('#Toronto_1', .5, { drawSVG: '0'}, "-=0.3")
        .from('#Toronto_2', 5, { drawSVG: '0'}, "-=0.3")
        .from('#Toronto_3', .5, { drawSVG: '0'}, "-=0.3")
        .to("#Streetcar", { onComplete: function() {
  this.targets().forEach(elem => elem.classList.add("streetcar-animation"));
}})
      // Animate the background buildings and baseline
      // Animate the rest of the iconic buildings with DrawSVG
      // Animate the clouds, make them move left and right
      // Animate the waves with MorphSVG
      // Animate the text
      // Animate the streetcar by adding the streetcar-animation class

      // Bonus:
      // Animate glowing stars
    }
  },
  watch: {
    section() {
      if(this.section == 2) {
        setTimeout(() => {
          this.startSVG()
        }, 300)
      }
      let numberOfSections = document.querySelectorAll('.aboutSection').length
      if(this.section >= this.lastSection) {
        document.querySelectorAll('.aboutSection')[numberOfSections - this.section].style.transform = 'translate(0, -100vh)'
        if(window.innerWidth >= 1100) {
          document.querySelectorAll('.part1')[numberOfSections - this.section].style.transform = 'translate(0, -80vh)'
        }
      } else if(this.section === 0) {
        document.querySelectorAll('.aboutSection')[numberOfSections - 1].style.transform = 'translate(0, 0)'
        document.querySelectorAll('.part1')[numberOfSections - 1].style.transform = 'translate(0, 0)'
        setTimeout(() => {
          document.querySelectorAll('.aboutSection, .part1').forEach(el => {
            el.style.transform = 'translate(0, 0)'
          })
        }, 300)
      } else {
        document.querySelectorAll('.aboutSection')[numberOfSections - this.section - 1].style.transform = 'translate(0, 0)'
        document.querySelectorAll('.part1')[numberOfSections - this.section - 1].style.transform = 'translate(0, 0)'
      }

      this.lastSection = this.section
    },

    CMS() {
      this.CMS.body[0].items.forEach(item => {
        this.meetClement.push({
          headline: item.about_headline[0].text.replace(/\s+/g, ' '),
          par1: item.about_paragraph_1[0].text.replace(/\s+/g, ' '),
          par2: item.about_paragraph_2[0].text.replace(/\s+/g, ' '),
          button: item.about_button_text[0].text.replace(/\s+/g, ' ')
        })
      })
    }
  },

  beforeCreate() {
    // CMS DEMONSTRATION
    this.$prismic.client.getSingle('about')
    .then(doc => {
      this.CMS = doc.data
    })
  },

  data() {
    return {
      showSVGContent: false,
      lastSection: 0,
      transitionInProgress: false,
      meetClement: [],
      CMS: null
    }
  },

  computed: {
    meetClementStore() {
      return this.$store.state.meetClement
    },

    section() {
      return this.$store.state.section
    }
  }
}
</script>

<style lang="sass">

$color1: rgba(38, 70, 83, 1)
$color2: rgba(42, 157, 143, 1)
$color3: rgba(233, 196, 106, 1)
$color4: rgba(244, 162, 97, 1)
$color5: rgba(231, 111, 81, 1)

.reveal
  position: absolute
  top: 0
  right: 0
  bottom: 0
  left: 0
  height: 100%
  background: white



#Moon
  animation: spin 3s linear infinite

#cn_base_l
  fill: #D7F7F5
  stroke: #040e3e

#cn_base_r, #lower_deck, #upper_deck
  fill: #75CAC3
  stroke: #040e3e

#main_deck
  fill: #2A6171
  stroke: #040e3e

#inner_deck
  fill: #B1FDEB
  stroke: #040e3e
  transition: all .2s ease
  &:hover
    fill: url(#disco-gif)
    transform: scale(1.2)
    stroke: #B1FDEB

#needle_base_1, #needle_base_2
  fill: #A9FCFA
  stroke: #040e3e

#needle
  fill: none
  stroke: #A9FCFA
  stroke-width: 3

.streetcar_white
  fill: #FEFEFE
  stroke: #040e3e

.streetcar_window
  fill: #1D0444
  stroke: #040e3e

.streetcar_red
  fill: #ca1a75
  stroke: #040e3e

#Stars *
  stroke: #8BD6D2

#Stars-glow polygon
  fill: white
  filter: url('#glow')
  opacity: 0
  &.star-glow-show
    opacity: 1

.hide, #Streetcar, #Waves-I path
  opacity: 0

/* Hiding some elements from the beginning to be invisible

@keyframes spin
  0%
    transform: rotate(360deg) scale(1)

  50%
    transform: rotate(360deg) scale(1.1)

  100%
    transform: rotate(720deg) scale(1)


/* CSS Animations for Streecar
@keyframes streetcar
  0%
    transform: translate(50vw)
    opacity: 0

  10%
    opacity: 0

  20%
    opacity: 1

  30%
    transform: translate(-10vw)
    opacity: 1

  70%
    transform: translate(-10vw)
    opacity: 1

  80%
    opacity: 1

  90%
    opacity: 1

  100%
    transform: translate(-100vw)
    opacity: 0

.streetcar-animation
  animation: streetcar 6s ease-in-out infinite

.aboutContainer
  width: 100vw
  height: 100vh
  position: absolute
  left: 0
  top: 0
  bottom: 0
  right: 0
  
.part1, .part2
  width: 50vw
  height: 100vh
  transition: all 1s ease-in
  @media (max-width: 1100px)
    width: 100vw
    height: 50vh

.aboutSection
  width: 100vw
  height: 100vh
  display: flex
  position: fixed
  transition: all 0.8s ease-in-out 0.2s
  @media (max-width: 1100px)
    flex-direction: column !important
  &:nth-child(2n)
    flex-direction: row-reverse

  .part1
    div
      display: none
    .revealContent
      display: block !important
      background-color: #040e3e
      height: 100vh
      @media (max-width: 1100px) and (min-width: 600px)
        position: relative
        bottom: 5vh
        height: 50vh !important
        svg
          margin: 0 !important
          position: absolute
          top: -12.5vh
          left: 5vw
          right: 0
          width: 90vw
      @media (max-width: 600px) and (min-width: 330px)
        position: relative
        bottom: 5vh
        height: 36vh !important
        margin-bottom: -3vh !important
        svg
          margin: 0 !important
          position: absolute
          top: -20vh
          left: 7.5vw
          right: 0
          width: 85vw
      @media (max-width: 330px)
        position: relative
        bottom: 5vh
        height: 31vh !important
        margin-bottom: -7vh !important
        svg
          margin: 0 !important
          position: absolute
          top: -23vh
          left: 15vw
          right: 0
          width: 70vw
      @media (orientation: landscape) and (max-width: 1000px)
        display: none !important
      svg
        height: 80vh
        margin-top: 10vh
      @media (max-width: 1400px) and (min-width: 1300px)
        svg
          height: auto !important
          width: 50vw !important
          margin-top: 15vh !important
        
    .clementAbout
      width: 45%
      display: block
      margin: 10% 25% 0 25%
      height: auto
      @media (max-width: 1100px)
        margin: 10% 35% -8% 35% !important
        width: 30%
      @media (max-width: 330px)
        width: 20% !important
        margin: 10% 40% -8% 40% !important
      @media (orientation: landscape) and (max-width: 1320px)
        display: none !important
    @media (max-width: 1100px)
      background-color: #f29144
      div
        display: block
        margin: 15vh auto 0 auto
        @media (max-width: 1100px)
          margin-top: 2vh !important
        position: relative
        z-index: 200
        h1
          @media (max-width: 600px)
            font-size: 23px
          @media (max-width: 330px)
            font-size: 20px
            margin: 30px 0 0 0 !important
          margin: 0 0 5px 0
          @media (orientation: landscape) and (max-width: 1320px)
            font-size: 20px
            margin-top: 10vh
          @media (orientation: landscape) and (min-height: 600px)
            font-size: 35px
            margin-bottom: 10px
        span
          text-align: left
          display: block
          padding: 5px 10vw
          @media (min-width: 600px) and (max-width: 1100px)
            font-size: 20px
          @media (max-width: 600px) and (orientation: portrait)
            font-size: 1.2rem
          @media (orientation: landscape) and (max-width: 1320px) and (max-height: 345px)
            font-size: 13px
          @media (orientation: landscape) and (max-width: 1320px) and (min-height: 345px) and (max-height: 600px)
            font-size: 14px
          @media (orientation: landscape) and (min-height: 600px)
            font-size: 23px
        .goToClementLabs
          margin-top: 40px
          @media (max-width: 600px)
            h3
              font-size: 15px
          @media (max-width: 650px) and (max-height: 850px) and (min-height: 700px)
            position: relative
            top: 10vh
          @media (min-width: 650px) and (min-height: 800px)
            position: relative
            top: -1vh
          @media (max-width: 650px) and (max-height: 800px)
            position: relative
            top: 3px
          @media (max-width: 350px)
            margin-top: 0
          @media (orientation: landscape) and (max-width: 1320px) and (max-height: 345px)
            margin-top: 3px
            h3
              font-size: 15px !important
          @media (orientation: landscape) and (max-width: 1320px) and (min-height: 345px) and (max-height: 600px)
            margin-top: 15px
            h3
              font-size: 17px !important
          @media (orientation: landscape) and (min-height: 600px)
            margin-top: 35px
            h3
              font-size: 25px !important
          h3
            margin: 0 0 20px 0
            @media (max-width: 350px)
              display: none
          img
            position: relative
            @media (max-width: 350px)
              top: 10px
            z-index: 500
            width: 40px
            height: 40px
            animation: float-y 2.5s ease-in-out infinite
    background-color: $color4

  .part2
    background-color: #f29144
    div
      width: 80%
      height: 40%
      margin: 20% 10%
      @media (max-width: 1100px)
        display: none
      h1
        text-align: left
        margin: 0 0 0 1%
        font-size: 2.5rem
      span
        text-align: left
        display: block
        margin: 10px
        //font-size: 1.5rem
        font-size: 19px
        @media (max-width: 1700px)
          font-size: 1.7rem
      .goToClementLabs
        margin-top: 15%
        h3
          margin: 0 0 20px 0
        img
          width: 2.5vw
          height: 2.5vw
          animation: float-y 2.5s ease-in-out infinite


.aboutSection:nth-child(3)
  .part1
    background-color: #f4a261
    position: relative
    z-index: 200
  .part2
    background-color: #f29144
    @media (max-width: 1100px)
      background-color: #f4a261

.aboutSection:nth-child(2)
  .part1
    background-color: $color5
  .part2
    background-color: #e67d63
    @media (max-width: 1100px)
      background-color: $color5

.aboutSection:nth-child(1)
  transform: translate(0) !important
  .part1
    background-color: #3ea398
    transform: translate(0) !important
    @media (max-width: 1100px)
      background-color: $color2 !important
      z-index: 200 !important
  .goToClementLabs
    img
      animation: float-y-180 2.5s ease-in-out infinite !important
  .part2
    transform: translate(0) !important
    background-color: $color2
    @media (max-width: 1100px)

</style>

<style lang="sass" scoped>
svg *
  stroke-width: 2
  stroke-linecap: round
  stroke-miterlimit: 10
  transform-origin: 50% 50%
  transform-box: fill-box

.BasicStroke
  stroke: #8BD6D2
  fill: transparent

.BasicFill
  fill: #040e3e
  stroke: #8BD6D2
</style>